define('calc10/views/drop-target', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].View.extend({
        classNames: ["small-centered", "dropTarget"],

        dragOver: function dragOver(e) {
            e.preventDefault();
        },

        drop: function drop(e) {
            e.preventDefault();
            try {
                var image = new Image();
                if (e.dataTransfer.files.length > 0) {
                    var fr = new FileReader();
                    var self = this;
                    fr.addEventListener("load", function () {
                        image.src = fr.result;
                        self.get("controller").send("imageDropped", image);
                    });
                    fr.readAsDataURL(e.dataTransfer.files[0]);
                } else {
                    image.src = window.$(e.dataTransfer.getData("text/html")).attr("src");
                    this.get("controller").send("imageDropped", image);
                }
            } catch (ex) {
                console.log(ex);
            }
        }
    });

});